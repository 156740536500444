import '../index.css';
import '../styles/backup-zeroheight.css';
import '../node_modules/@digital-engineering-experiences/wb-webcomponents/dist/style.css';
import '../index.css';

function MyApp({
    Component,
    pageProps,
}: {
    Component: React.ComponentType<any>;
    pageProps: any;
}) {
    return <Component {...pageProps} />;
}

export default MyApp;
